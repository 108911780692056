const MonthData =
    [
        {value: "1", label: "01"}, 
        {value: "2", label: "02"}, 
        {value: "3", label: "03"}, 
        {value: "4", label: "04"}, 
        {value: "5", label: "05"}, 
        {value: "6", label: "06"}, 
        {value: "7", label: "07"}, 
        {value: "8", label: "08"}, 
        {value: "9", label: "09"}, 
        {value: "10", label: "10"}, 
        {value: "11", label: "11"}, 
        {value: "12", label: "12"}
    ]

export default MonthData